import React, { Component } from 'react';
import Api from '../../service/Api';
import Validate from '../../service/Validate';
import FormInput from './FormInput';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                Nombre: '',
                ApellidoP: '',
                ApellidoM: '',
                Rut: '',
                Email: '',
                Ext: '+56',
                Telefono: '',
                Mensaje: '',
                TipoProductoText: '',
                TipoNecesidadText: '',
                RegionText: '',
                destino_email: this.props.destino_email,
                type_form: this.props.type_form,
                parque: this.props.parque,
                producto: this.props.producto,
                cantidad: this.props.cantidad,
                precio: this.props.precio
            },
            txtMsj: '',
            AlertClass: '',
            disabledButton: false

        };
        this.form = React.createRef()
        this.setForm = this.setForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    async setForm() {

        if(this.state.data.Rut != '') {
            if(!(Validate.validaRut(this.state.data.Rut.replaceAll('.','')))) {
                this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Rut ingresado no es válido.', AlertClass: "alert-danger" });
                return false; 
            }
          }

        //   if(this.state.data.TipoNecesidadText == 'Seleccionar Tipo Necesidad' || this.state.data.TipoNecesidadText == '') {
        //     this.setState({ txtMsj: 'Debe Seleccionar un Tipo de Necesidad' , AlertClass: "alert-danger" });
        //     return false; 
        //   }
          

        let mensaje = "";
        let rules = {
            texto: 'Nombre',
            texto1: 'ApellidoP',
            texto2: 'ApellidoM',
            rut: 'Rut',
            email: 'Email',
            numerico: 'Telefono',
            extension: 'Ext',
            selector: 'RegionText',
            selector1: 'TipoProductoText',
            selector2: 'TipoNecesidadText'
        };
        let validate = {};
        for (var [type, input] of Object.entries(rules)) {
            let resul = Validate.ValidateForm(this.state.data[input], type.replace(/[0-9]/g, ''), input)
            if (resul !== '') {
                mensaje += resul
                validate[input] = [resul];
            }
        }
        Validate.validate(this.form.current, validate)
        if (mensaje !== "") {
            //this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + mensaje, AlertClass: "alert-danger" });
            this.setState({ txtMsj: "Se encuentran campos con errores, favor revisar", AlertClass: "alert-danger" });
            return false;
        }
        this.setState({ disabledButton: true, nameButton: 'ENVIANDO..' });
        const response = await Api.setForm(this.state.data);
        if (response.result === 'true') {
            this.setState({
                Nombre: '',
                ApellidoP: '',
                ApellidoM: '',
                Rut: '',
                Email: '',
                Ext: '+56',
                Telefono: '',
                Mensaje: '',
                txtMsj: "Correo enviado correctamente",
                AlertClass: "alert-success",
                disabledButton: false
            });

            this.setState({ disabledButton: false, nameButton: "ENVIAR" });

            setTimeout(() => {

                window.location = "/gracias/";

            }, 1000);

        } else {
            this.setState({
                txtMsj: "Ocurrió un problema, favor intentar nuevamente",
                AlertClass: "alert-danger",
                disabledButton: false
            });
            this.setState({ disabledButton: false, nameButton: "ENVIAR" });
        }
    }
    onChange(event) {
        const { name, value } = event.target;
        const { data } = this.state;
        if (name === 'RegionText' && value === 'Maipú') {
            data['TipoProductoText'] = 'value';
        }
        if ( event.target.name == 'Rut') {
            console.log('vuino al Rut')
            data[name] = event.target.value.replace(/[.-]/g, "")
            .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
            console.log(data);
        } else {
            data[name] = event.target.value;
        }
        this.setState({
            ...data,
            data :data
          });

    }
    render() {
        const { txtMsj, AlertClass, disabledButton, data } = this.state;
        const { RegionText } = data;
        const { nameButton } = this.props;
        return (
            <>
                <form ref={this.form}>
                    <FormInput
                        onChange={this.onChange}
                        setForm={this.setForm}
                        txtMsj={txtMsj}
                        AlertClass={AlertClass}
                        nameButton={nameButton}
                        disabledButton={disabledButton}
                        validateSelect={RegionText}
                        value={this.state.data}
                    />
                </form>
            </>
        );
    }
}
export default Form;