import React, { Component } from "react";
import Api from "../../service/Api";
import Validate from "../../service/Validate";
import { Button, Alert } from "reactstrap";
import ciudad from "./../Data/Ciudad.json";

class FormContacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Nombre: "",
        ApellidoP: "",
        ApellidoM: "",
        Rut: "",
        Email: "",
        Ext: "+56",
        Telefono: "",
        Mensaje: "",
        Motivo: "",
        Parque: "",
        destino_email: "3j7l5g2w@robot.zapier.com",
        type_form: "Contacto Sendero",
      },
      txtMsj: "",
      AlertClass: "",
      disabledButton: false,
      nameButton: "ENVIAR",
    };
    this.form = React.createRef();
    this.setForm = this.setForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  async setForm() {
      
    if(this.state.data.Rut != '') {
      if(!(Validate.validaRut(this.state.data.Rut.replaceAll('.','')))) {
          this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Rut ingresado no es válido.', AlertClass: "alert-danger" });
          return false; 
      }
    }

    let mensaje = "";
    let rules = {
      texto: "Nombre",
      texto1: "ApellidoP",
      texto2: "ApellidoM",
      rut: "Rut",
      email: "Email",
      numerico: "Telefono",
      extension: "Ext",
      selector1: "Motivo",
      selector2: "Parque",
    };
    let validate = {};
    for (var [type, input] of Object.entries(rules)) {
      let resul = Validate.ValidateForm(
        this.state.data[input],
        type.replace(/[0-9]/g, ""),
        input
      );
      if (resul !== "") {
        mensaje += resul;
        validate[input] = [resul];
      }
    }
    Validate.validate(this.form.current, validate);
    if (mensaje !== "") {
      this.setState({
        txtMsj: "Se encuentran campos con errores, favor revisar",
        AlertClass: "alert-danger",
      });
      return false;
    }

    this.setState({ disabledButton: true, nameButton: "CARGANDO.." });

    const response = await Api.setForm(this.state.data);
    if (response.result === "true") {
      this.setState({
        Nombre: "",
        ApellidoP: "",
        ApellidoM: "",
        Rut: "",
        Email: "",
        Ext: "+56",
        Telefono: "",
        Mensaje: "",
        txtMsj: "Correo enviado correctamente",
        AlertClass: "alert-success",
        disabledButton: false,
      });

      this.setState({ disabledButton: false, nameButton: "ENVIAR" });

      setTimeout(() => {

        window.location = "/gracias/";

    }, 1000);
    
    } else {
      this.setState({
        txtMsj: "Ocurrió un problema, favor intentar nuevamente",
        AlertClass: "alert-danger",
        disabledButton: false,
      });
      this.setState({ disabledButton: false, nameButton: "ENVIAR" });
    }
  }
  onChange(event) {

    const { name, value } = event.target;
    const { data } = this.state;
    if (event.target.name == "Rut") {
      //console.log(value);
      data[name] =  event.target.value
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");

        //console.log(data[name]);

    } else {
      data[name] =  event.target.value;
    }
    this.setState({
      ...data,
      data :data
    });

    //console.log(this.state.data);

  }
  render() {
    const { nameButton, disabledButton } = this.state;
    return (
      <>
        <form ref={this.form}>
          <div className="row-form row">
            <div className="col-12 col-sm-12">
              <input
                className="form-control"
                type="text"
                name="Nombre"
                id="Nombre"
                placeholder="Nombre *"
                onChange={this.onChange}
                value={this.state.Nombre}
                title={""}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="text"
                name="ApellidoP"
                id="ApellidoP"
                placeholder="Apellido Paterno *"
                onChange={this.onChange}
                value={this.state.Apellido}
                title={""}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="text"
                name="ApellidoM"
                id="ApellidoM"
                placeholder="Apellido Materno*"
                onChange={this.onChange}
                value={this.state.Apellido}
                title={""}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="text"
                name="Rut"
                id="Rut"
                placeholder="Rut *"
                onChange={this.onChange}
                value={this.state.Rut}
                title={""}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="email"
                name="Email"
                id="Email"
                placeholder="Email *"
                onChange={this.onChange}
                value={this.state.Email}
                title={""}
              />
            </div>
            <div className="col-3 col-sm-3">
              <input
                className="form-control text-center"
                type="number"
                name="Ext"
                id="Ext"
                placeholder="+56"
                onChange={this.onChange}
                value="+56"
                disabled
                title={""}
              />
            </div>
            <div className="col-9 col-sm-9">
              <input
                className="form-control"
                type="email"
                name="Telefono"
                id="Telefono"
                placeholder="Teléfono *"
                onChange={this.onChange}
                maxLength={9}
                value={this.state.Telefono}
                pattern="[0-9]{0,9}"
                title={""}
                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } else {
                  if(event.currentTarget.value.trim().length > 9) {
                    event.preventDefault();
                  }
                } }}
              />
            </div>
            <div className="col-12 col-sm-12">
              <select
                name="Motivo"
                id="Motivo"
                className="form-control"
                onChange={this.onChange}
              >
                <option value="0">Seleccionar Motivo</option>
                <option value="consulta">Consulta</option>
                <option value="reclamo">Reclamo</option>
              </select>
            </div>
            <div className="col-12 col-sm-12">
              <select
                name="Parque"
                id="Parque"
                className="form-control"
                onChange={this.onChange}
              >
                <option value="0">Seleccionar Parque</option>
                {ciudad.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-12">
              <textarea
                className="form-control"
                type="textarea"
                name="Mensaje"
                id="Mensaje"
                placeholder="Mensaje..."
                onChange={this.onChange}
                value={this.state.Mensaje}
                style={{height : '150px'}}
              ></textarea>
            </div>
            <div className="form-group row">
              <div className="col-sm-12 text-center">
                {this.state.txtMsj !== "" ? (
                  <Alert className={"alert " + this.state.AlertClass}>
                    {this.state.txtMsj}
                  </Alert>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="text-center col-12 col-sm-12">
              <Button
                className="btn btn-verde button"
                onClick={this.setForm}
                disabled={disabledButton}
              >
                {nameButton}
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
export default FormContacto;
