import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const ModalRender = ({ open, setOpenModal, anfora }) => {
  const [opened, setOpened] = useState(open);
  // console.log(opened);
  return (
    <Modal isOpen={opened} size={'md'} centered>
      <ModalBody>
        <Button
          color=""
          style={{
            position: 'absolute',
            right: '10px',
            border: '1px solid gray',
          }}
          onClick={(e) => setOpenModal()}
        >
          Cerrar
        </Button>
        <div style={{ fontSize: '28px', fontWeight: 'bold', color: '#000' }}>
          {anfora.name}
        </div>
        <br />
        <div className="row">
          <div className="col-lg-7 col-md-6">
            <p>
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                Materialidad:{' '}
              </span>
              <span>{anfora.material}</span>
            </p>
            <p>
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                Sugerencia de uso:{' '}
              </span>
              <span>{anfora.sugerencia}</span>
            </p>
          </div>
          <div className="col-lg-5 col-md-6">
            <img
              className="img img-responsive"
              src={anfora.image}
              alt={anfora.name}
              height="150"
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalRender;
